<template>
  <v-form ref="form" v-model="valid" @submit.prevent="tryToLogIn">
    <h1 class="text-center custom-title-large">Bem-vindo</h1>
    <v-row justify="center">
      <v-col cols="8">
        <v-text-field
          label="E-mail"
          v-model="username"
          :rules="rules"
          ref="email"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="8">
        <v-text-field
          label="Senha"
          ref="password"
          v-model="password"
          :rules="rules"
          :type="visible ? 'password' : 'text'"
          :append-icon="visible ? 'visibility' : 'visibility_off'"
          @click:append="() => (visible = !visible)"
          required
          autocomplete="off"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-col cols="12" class="text-center my-5">
        <v-btn
          :loading="tryingToLogIn"
          type="submit"
          @submit="tryToLogIn"
          class="custom-btn-green rounded-0 btn-large"
        >
          Entrar
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-center my-1">
        <router-link class="custom-text-link" :to="'/login/recover'"
          >Esqueceu a senha?</router-link
        >
      </v-col>
      <v-col cols="12" class="text-center">
        <router-link class="custom-text-link" :to="'/new-registering'">
          Ainda não é cliente? Cadastre-se
        </router-link>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { authMethods } from '@state/helpers'

export default {
  name: 'login-enter',
  data() {
    return {
      valid: false,
      visible: true,
      tryingToLogIn: false,
      authError: false,
      username: '',
      password: '',
      rules: [
        (v) => !!v || 'Preencha corretamente',
        (v) => !this.authError || 'Email ou senha invalido',
      ],
    }
  },
  methods: {
    ...authMethods,
    tryToLogIn() {
      this.authError = false
      if (!this.$refs.form.validate()) return
      this.tryingToLogIn = true
      this.logIn({
        username: this.username,
        password: this.password,
      })
        .then((success) => {
          if (success) {
            this.tryingToLogIn = false
            this.$router.push(
              this.$route.query.redirectFrom || { name: 'home' }
            )
          }
        })
        .catch((error) => {
          console.log('error', error)
          this.tryingToLogIn = false
          this.authError = true
          this.logError = error
          this.$refs.form.validate()
        })
    },
  },
}
</script>

<style module lang="scss">
@import '@design';
</style>
